import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/max/Documents/node-projects/maxs-portfolio/src/components/page-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "https://wdig.ml"
      }}>{`Where Does It Go?`}</a>{` lets you find out where a URL goes without actually going there. It runs a real web browser and shows you everywhere it goes, including a screenshot of the final destination. It also warns you if the final destination is a rickroll or flagged as unsafe by `}<a parentName="p" {...{
        "href": "https://safebrowsing.google.com/"
      }}>{`Google Safe Browsing`}</a>{`.`}</p>
    <p>{`WDIG is build with `}<a parentName="p" {...{
        "href": "https://nuxtjs.org/"
      }}>{`Nuxt.js`}</a>{`, is hosted on Firebase Hosting & Functions and is open source `}<a parentName="p" {...{
        "href": "https://github.com/pmaxhogan/wdig"
      }}>{`on Github`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      